import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import { withRouter } from 'react-router';
import FontAwesome from 'react-fontawesome';
import { usersUpdate, addRecentlyCreated } from '../../actions';
import {
  GenericInput,
  ImageInput,
  DropdownInput,
  Button,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
  DatePicker,
  SuccessPopup,
  ProfilePic,
} from '../../components';
import { userActions, fileActions, typeActions } from '../../webapi';
import { checkLoggedIn, isTheBest, validateAccess } from '../../session';
import { get1400, isEmail } from '../../helper';
import { requiresEmail, defaultProfileImage, PHONE_COUNTRY, PHONE_COUNTRY_CODE, PHONE_NUMBER_MINLENGTH } from '../../config';
import { Text } from '../../components/text';
import 'react-phone-input-2/lib/style.css';

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      userPhone: '',
      userEmail: '',
      profilePic: defaultProfileImage,

      uploadingImage: false,

      userType: '',
      userBirthday: moment('1900-01-01', 'YYYY-MM-DD').format('YYYY-MM-DD'),
      birthdayText: moment('01/01/1900', 'DD/MM/YYYY').format('DD/MM/YYYY'),
      paymentType: null,

      unitNumber: '',

      submitting: false,
      success: false,

      PageError: null,

      sites: [],
      types: [],
      typesLoaded: [],
      allTypes: [],
      roles: [
        {
          site: this.props.auth.site,
          type: '',
        },
      ],

      site: this.props.auth.site,

      showWarnings: false,
      showDate: false,

      tempUser: {},
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    /*   this.onNewProps(nextProps); */
  }

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
  }

  componentDidMount() {
    if (!validateAccess(this.props.auth.site, 'userManagement', this.props.auth, true)) {
      this.props.history.push('/mastermenu');
    } else {
      this.getSites();
      this.getUserTypes();
    }
    this.props.addRecentlyCreated('users');
  }

  getSites() {
    typeActions
      .getSites()
      .then((res) => {
        if (
          !_.some(res.data, (s) => {
            return s.Id === 'hq';
          })
        ) {
          res.data.push({
            Id: 'hq',
            active: true,
            siteName: 'HQ',
          });
        }
        if (
          isTheBest(this.props.auth, true) &&
          !_.some(res.data, (s) => {
            return s.Id === 'plussSpace';
          })
        ) {
          res.data.push({
            Id: 'plussSpace',
            active: true,
            siteName: 'PlussSpace',
          });
        }
        if (
          !isTheBest(this.props.auth, true) &&
          _.some(res.data, (s) => {
            return s.Id === 'plussSpace';
          })
        ) {
          res.data.splice(
            _.findIndex(res.data, (s) => {
              return s.Id === 'plussSpace';
            }),
            1,
          );
        }
        res.data.forEach((e) => {
          e.Title = e.siteName;
          e.Key = e.Id;
        });
        this.setState({
          sites: res.data,
        });
      })
      .catch((error) => {});
  }

  getUserTypes(site) {
    const siteToGet = site || (this.props.auth.site === 'hq' ? 'all' : this.props.auth.site);
    if (_.includes(this.state.typesLoaded, siteToGet)) {
      return;
    }
    typeActions
      .getUserTypes(siteToGet)
      .then((res) => {
        res.data.forEach((e) => {
          e.Title = e.displayName;
          e.Key = e.typeName;
        });
        const newTypes = _.uniqBy(_.union(this.state.types, res.data), (t) => {
          return `${t.site}_${t.typeName}`;
        });
        const newTypesLoaded = this.state.typesLoaded;
        newTypesLoaded.push(siteToGet);
        this.setState({
          types: _.uniqBy(newTypes, (t) => {
            return `${t.site}_${t.typeName}`;
          }),
          typesLoaded: newTypesLoaded,
        });
      })
      .catch((error) => {});
  }

  getTypeTitle(role) {
    const typeObject = _.find(this.getTypeOptions(role.site), (t) => {
      return t.typeName === role.type && t.site === role.site;
    });
    if (typeObject) {
      return typeObject.displayName;
    }
    return '';
  }

  getSiteTitle(site) {
    const siteObject = _.find(this.state.sites, (t) => {
      return t.Id === site;
    });
    if (siteObject) {
      return siteObject.siteName;
    }
    return site;
  }

  selectSite(index, key, e) {
    const newRoles = _.cloneDeep(this.state.roles);
    const previousSite = newRoles[index].site;
    newRoles[index].site = key;
    if (previousSite !== key) {
      this.getUserTypes(key);
      newRoles[index].type = '';
      this.setState({
        roles: newRoles,
      });
    }
  }

  addSite() {
    const newRoles = _.cloneDeep(this.state.roles);
    newRoles.push({
      site: '',
      type: '',
    });
    this.setState({
      roles: newRoles,
    });
  }

  removeSite(index) {
    this.state.roles.splice(index, 1);
    const newRoles = _.cloneDeep(this.state.roles);
    this.setState({
      roles: newRoles,
    });
  }

  selectType(index, key, e) {
    const newRoles = _.cloneDeep(this.state.roles);
    newRoles[index].type = key;
    this.setState({
      roles: newRoles,
    });
  }

  getSiteOptions(site) {
    const options = _.filter(this.state.sites, (s) => {
      return (
        !_.some(this.state.roles, (r) => {
          return r.site === s.Id;
        }) || s.Id === site
      );
    });
    return options;
  }

  getTypeOptions(site) {
    const options = _.filter(this.state.types, (t) => {
      return t.site === site;
    });
    if (validateAccess('hq', 'master', this.props.auth)) {
      if (
        !_.some(options, (t) => {
          return t.typeName === 'master';
        })
      ) {
        options.push({
          typeName: 'master',
          displayName: 'Master',
          Title: 'Master',
          Key: 'master',
          site: site,
        });
      }
    } else {
      const index = _.findIndex(options, (t) => {
        return t.typeName === 'master';
      });
      if (index > -1) {
        options.splice(index, 1);
      }
    }
    return options;
  }

  isEmail(email) {
    const atpos = email.indexOf('@');
    const dotpos = email.lastIndexOf('.');
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= email.length) {
      return false;
    }
    return true;
  }

  handleDateTextChange(value) {
    const newU = { birthdayText: value };
    const m = moment(value, 'DD/MM/YYYY');

    if (m.isValid() && m.year() > 1900) {
      newU.userBirthday = m.format('YYYY-MM-DD');
    }
    this.setState(newU);
  }

  handleDateChange(date) {
    var stateChange = {
      userBirthday: date,
      birthdayText: moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      showDate: false,
    };
    this.setState(stateChange);
  }

  validateRoles() {
    if (_.isEmpty(this.state.roles)) {
      return false;
    }
    let anyInvalid = false;
    this.state.roles.forEach((r) => {
      if (_.isEmpty(r.site) || _.isEmpty(r.type)) {
        anyInvalid = true;
      }
    });
    return !anyInvalid;
  }

  validateForm() {
    if (this.state.userName.length < 2) {
      return false;
    }

    const emptyPhone = _.isEmpty(this.state.userPhone) || this.state.userPhone === PHONE_COUNTRY_CODE || this.state.userPhone === '+';
    const emptyEmail = _.isEmpty(this.state.userEmail);

    // if (emptyPhone && emptyEmail) {
    //     return false;
    // }
    if (requiresEmail && emptyEmail) {
      return false;
    }

    if (!emptyPhone && (this.state.userPhone.indexOf(PHONE_COUNTRY_CODE) !== 0 || this.state.userPhone.length < PHONE_NUMBER_MINLENGTH)) {
      return false;
    }
    if (!emptyEmail && !this.isEmail(this.state.userEmail)) {
      return false;
    }

    if (!this.validateRoles()) {
      return false;
    }

    return true;
  }

  handleSubmit() {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    if (this.state.submitting) {
      return;
    }

    this.setState({ showWarnings: false, submitting: true, PageError: null });

    var user = {
      profilePic: _.isEmpty(this.refs.imageInput.getWrappedInstance().getValue())
        ? null
        : get1400(this.refs.imageInput.getWrappedInstance().getValue()),
      displayName: this.state.userName,
    };

    // Ensure phone number begins with a plus
    if (!_.isEmpty(this.state.userPhone) && this.state.userPhone !== '+') {
      user.phoneNumber = this.state.userPhone ? String(this.state.userPhone).replace(/[^\d]/g, '') : '';
      if (user.phoneNumber.charAt(0) !== '+') {
        user.phoneNumber = '+' + user.phoneNumber;
      }
    }

    if (!_.isEmpty(this.state.userEmail) && this.isEmail(this.state.userEmail)) {
      user.email = this.state.userEmail.toLowerCase();
      user.emailVerified = false;
    }

    var userExtra = {
      type: this.state.roles[0].type,
      location: this.state.roles[0].site,
      roles: this.state.roles,
      birthday: this.state.userBirthday,
      paymentType: this.state.paymentType,
    };
    if (this.isAResident(true)) {
      userExtra.unit = this.state.unitNumber;
    }
    userActions
      .createNewUser(user, userExtra)
      .then((res) => {
        if (res.data.userCreationFail) {
          this.setState({
            PageError: res.data.message,
            submitting: false,
          });
        } else {
          this.setState({
            success: true,
            submitting: false,
            tempUser: res.data.user,
          });
          this.props.usersUpdate(this.props.auth.site);
        }
      })
      .catch((error) => {
        this.setState({ submitting: false, PageError: 'Something went wrong. Please try again.' });
        console.log(error);
      });
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  handleOnPhoneChange(value) {
    this.setState({
      userPhone: `+${value}`,
    });
  }

  handleFileChange(event) {
    const file = event.target.files[0];
    if (!file || this.state.uploadingImage) {
      return;
    }
    this.setState({
      uploadingImage: true,
    });
    // fileActions.readBase64(file)
    //     .then((res) => {
    fileActions
      .uploadMediaAsync(file, file.name)
      .then((fileRes) => {
        this.setState({
          profilePic: get1400(fileRes),
          uploadingImage: false,
        });
      })
      .catch((uploadErrorRes) => {
        this.setState({
          uploadingImage: false,
        });
      });
  }

  hasLinkedType = () => {
    return _.some(this.state.types, (t) => {
      return t.category === 'family';
    });
  };

  isAResident(includeLinked = false) {
    const categories = includeLinked ? ['resident', 'family'] : ['resident'];
    return _.some(this.state.roles, (r) => {
      return _.some(this.state.types, (t) => {
        return r.site === t.site && r.type === t.typeName && categories.includes(t.category);
      });
    });
  }

  renderAddRole() {
    if (!validateAccess('hq', 'userManagement', this.props.auth)) {
      return null;
    }
    return (
      <div className="user_addSite" onClick={this.addSite.bind(this)}>
        Add additional site
      </div>
    );
  }

  renderRole(role, index) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
        <DropdownInput
          id="siteSelect"
          label="Site"
          placeholder="Site"
          value={this.getSiteTitle(role.site)}
          options={this.getSiteOptions(role.site)}
          onSelect={this.selectSite.bind(this, index)}
          style={{ marginRight: 16, marginBottom: 0 }}
          disabled={!validateAccess('hq', 'userManagement', this.props.auth)}
        />
        <DropdownInput
          id="typeSelect"
          label="Type"
          placeholder="Type"
          value={this.getTypeTitle(role)}
          options={this.getTypeOptions(role.site)}
          onSelect={this.selectType.bind(this, index)}
          style={{ marginBottom: 0 }}
          disabled={
            !validateAccess('hq', 'userManagement', this.props.auth) && !validateAccess(role.site, 'userManagement', this.props.auth)
          }
        />
        {validateAccess('hq', 'userManagement', this.props.auth) && this.state.roles.length > 1 && (
          <a
            onClick={() => {
              this.removeSite(index);
            }}
          >
            <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 24, cursor: 'pointer' }} name="minus-circle" />
          </a>
        )}
      </div>
    );
  }

  renderRoles() {
    return (
      <div style={{ marginTop: 16, marginBottom: 32 }}>
        {_.map(this.state.roles, (r, i) => {
          return this.renderRole(r, i);
        })}
        {this.renderAddRole()}
      </div>
    );
  }

  renderForm() {
    return (
      <div>
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          <Text type="formTitleLarge" className="marginBottom-24">
            New User
          </Text>
          <GenericInput
            id="userName"
            type="text"
            label="Name"
            placeholder="Insert name here"
            value={this.state.userName}
            onChange={(e) => this.handleChange(e)}
            isValid={() => {
              return this.state.userName.length > 1;
            }}
            showError={() => {
              return this.state.showWarnings && this.state.userName.length < 2;
            }}
            disabled={this.state.submitting}
            isRequired
            large
            alwaysShowLabel
          />
          <div style={{ width: 'fit-content' }}>
            {this.renderRoles()}
            {this.isAResident(true) && (
              <GenericInput
                id="unitNumber"
                type="text"
                label="Address"
                placeholder="Unit 111"
                value={this.state.unitNumber}
                onChange={(e) => this.handleChange(e)}
                alwaysShowLabel
              />
            )}
            <GenericInput
              id="birthdayText"
              label="Birthday"
              alwaysShowLabel
              placeholder="20/12/1960"
              value={this.state.birthdayText}
              onChange={(e) => this.handleDateTextChange(e.target.value)}
              onClick={(e) => this.setState({ showDate: !this.state.showDate })}
            />
            {this.state.showDate && (
              <DatePicker
                selectedDate={this.state.userBirthday}
                // selectedDate={moment(this.state.tempUser.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD')}
                selectDate={this.handleDateChange.bind(this)}
              />
            )}
          </div>
        </div>
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          <Text type="formTitleSmall" className="marginBottom-16">
            Profile Details
          </Text>
          <div style={{ marginBottom: 15 }}>
            <div className={'fieldLabel marginBottom-5'}>Phone number - Must use {PHONE_COUNTRY_CODE} format.</div>
            <PhoneInput
              id="userPhone"
              country={PHONE_COUNTRY}
              onlyCountries={[PHONE_COUNTRY]}
              onChange={(e) => this.handleOnPhoneChange(e)}
              countryCodeEditable={false}
              disableDropdown
              masks={{ au: '... ... ...', nz: '.. ... .....' }}
              enableLongNumbers={PHONE_COUNTRY === 'nz' ? 1 : false}
            />
          </div>
          <GenericInput
            id="userEmail"
            type="text"
            label="Email"
            placeholder="user@example.com"
            value={this.state.userEmail}
            onChange={(e) => this.handleChange(e)}
            showError={() => {
              return this.state.showWarnings && this.state.userEmail.length > 0 && !isEmail(this.state.userEmail);
            }}
            errorMessage="Not a valid email"
            isRequired={requiresEmail}
            isValid={() => {
              return !requiresEmail || isEmail(this.state.userEmail);
            }}
            alwaysShowLabel
          />
        </div>
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          <Text type="formTitleSmall" className="marginBottom-16">
            Profile Picture
          </Text>
          <ImageInput ref="imageInput" noMenu />
        </div>
      </div>
    );
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div>
        <Button
          inline
          buttonType="tertiary"
          onClick={() => {
            window.history.back();
          }}
          isActive
          style={{ marginRight: 16 }}
        >
          Cancel
        </Button>
        <Button inline buttonType="primary" onClick={this.handleSubmit.bind(this)} isActive={this.validateForm()}>
          Save
        </Button>
      </div>
    );
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return (
      <SuccessPopup
        text="User has been created"
        buttons={[
          {
            type: 'outlined',
            onClick: () => {
              this.props.history.push(`/usershub/user/${encodeURIComponent(this.state.tempUser.Id)}`);
            },
            text: 'View user',
          },
          {
            type: 'outlined',
            onClick: () => {
              window.history.back();
            },
            text: 'Go to home',
          },
        ]}
      >
        {!_.isEmpty(this.state.tempUser) && !_.isEmpty(this.state.tempUser.initialPassword) && (
          <p className="fontRegular fontSize-18 text-centered">
            The initial password is <span className="text-brandingAction">{this.state.tempUser.initialPassword}</span>.
          </p>
        )}
        {this.isAResident() && this.hasLinkedType() && !_.isEmpty(this.state.tempUser) && (
          <div className="flex flex-column flex-center" style={{ marginTop: 24, paddingLeft: 32, paddingRight: 32 }}>
            <ProfilePic className="paragraphresult_pic" size={65} image={this.state.tempUser.profilePic}></ProfilePic>
            <div className="fontRegular fontSize-18 marginTop-8" style={{ textAlign: 'center' }}>
              Would you like to create and link a user to {this.state.tempUser.displayName}
            </div>
            <Button
              buttonClassName="successPopup_button"
              buttonType={'outlined'}
              isActive
              onClick={() => {
                this.props.history.push(`/usershub/user/${encodeURIComponent(this.state.tempUser.Id)}?launchNewLink=true`);
              }}
            >
              Create and link new user
            </Button>
            <div style={{ width: '100%', borderBottom: '1px solid #d5dde4', paddingTop: 24 }} />
          </div>
        )}
      </SuccessPopup>
    );
  }

  onBackPress() {
    if (this.state.submitting) {
      return;
    }
    this.props.onBack(null);
  }

  renderError() {
    if (this.state.PageError) {
      return (
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          <div style={{ fontWeight: 'bold', color: 'red', fontSize: 20 }}>{this.state.PageError}</div>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <OverlayPage>
        {this.renderSuccess()}
        <OverlayPageContents noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--newPopup">
            <div style={{ marginBottom: 60 }}>
              {!this.state.success && this.renderForm()}
              {this.renderError()}
            </div>
          </OverlayPageSection>
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderSubmit()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

// const styles = {
//     profilePic: {
//         height: 120,
//         width: 120,
//         borderRadius: 60,
//         marginRight: 20,
//         border: '1px solid #aaa',
//         backgroundColor: '#ddd',
//         backgroundPosition: 'center',
//         backgroundSize: 'cover'
//     }
// };

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, { usersUpdate, addRecentlyCreated })(withRouter(AddUser));
